var GoogleMap = (function($) {

	var mapCenter = new google.maps.LatLng(43.300216, 22.018342);
	var mapZoom = 12;
	var mapType = google.maps.MapTypeId.ROADMAP;
	var mapRef;

	var initialize = function() {
		var mapCanvas = document.getElementById('map-canvas');
		var mapOptions = {
			center: mapCenter,
			zoom: mapZoom,
			mapTypeId: mapType
		};
		mapRef = new google.maps.Map(mapCanvas, mapOptions);
	};

	var setMarkers = function() {
		var mapMarker = new google.maps.Marker({
			position: mapCenter,
			map: mapRef,
			title: 'Mikon inženjering'
		});
	};

	var handleWindowResize = function() {
		$(window).resize(function() {
			google.maps.event.trigger(mapRef, "resize");
			mapRef.setCenter(mapCenter);
		});
	};

	return {
		show: function() {
			initialize();
			setMarkers();
			handleWindowResize();
		}
	};

})(jQuery);

(function($) {
	"use strict"; // Start of use strict

	// jQuery for page scrolling feature - requires jQuery Easing plugin
	$('a.page-scroll').bind('click', function(event) {
		var $anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: ($($anchor.attr('href')).offset().top - 50)
		}, 1250, 'easeInOutExpo');
		event.preventDefault();
	});

	// Highlight the top nav as scrolling occurs
	$('body').scrollspy({
		target: '.navbar-fixed-top',
		offset: 51
	});

	// Closes the Responsive Menu on Menu Item Click
	$('.navbar-collapse ul li a').click(function() {
		$('.navbar-toggle:visible').click();
	});

	// Offset for Main Navigation
	$('#mainNav').affix({
		offset: {
			top: 100
		}
	});

	// Initialize and Configure Scroll Reveal Animation
	window.sr = ScrollReveal();
	sr.reveal('.sr-icons', {
		duration: 600,
		scale: 0.3,
		distance: '0px'
	}, 200);
	sr.reveal('.sr-button', {
		duration: 1000,
		delay: 200
	});
	sr.reveal('.sr-contact', {
		duration: 600,
		scale: 0.3,
		distance: '0px'
	}, 300);

	// Initialize and Configure Magnific Popup Lightbox Plugin
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
		}
	});

	GoogleMap.show();

})(jQuery); // End of use strict
